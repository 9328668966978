<template>
  <section class="social-media social-media-accounts scroll-content">
    <h3 class="md-display-1">{{ $translate("social_media_accounts.title") }}:</h3>

    <section_description_preview>
      <div class="md-subheading">{{ $translate("social_media_accounts.message") }}</div>
    </section_description_preview>

    <div class="social-media__content">
      <section v-if="is_global_admin">
        <p class="md-headline social-media__sub-title">{{ $translate("social_media_posts.select_social_media") }}:</p>
        <md-content
          v-for="platform in social_media_providers"
          :key="platform"
          class="social-media__platform md-elevation-3"
          :class="{ 'social-media__page--selected': chosen_provider === platform}"
          @click="chosen_provider = platform" 
        >
          <img :src="`/static/icons/social-media-${platform}.svg`" :alt="platform">
        </md-content>

        <div v-if="chosen_provider" class="social-media-accounts__account-switch">
          <!-- Google specific sign_in button -->
          <md-button
            v-if="social_media_provider_names.google === chosen_provider && !correct_auth_user_data.name"
            class="md-raised social-media-accounts__google-signin"
            @click="fetch_platform_entities(social_media_provider_names.google)"
          >
            <md-icon><img :src="`/static/icons/social-media-google.svg`"></md-icon>
            {{ $translate("social_media_accounts.google_signin") }}
          </md-button>
          <md-button v-else class="md-raised" @click="switch_account">
            {{ $translate("social_media_accounts.switch_account") }}
          </md-button>

          <span v-if="correct_auth_user_data.name">
            {{ $translate("social_media_accounts.logged_in_as") }}: <b>{{ correct_auth_user_data.name }}</b>
          </span>
        </div>

        <hr v-if="chosen_provider"/>

        <md-tabs
          v-if="chosen_provider"
          class="full-width-tabs-navigation"
          :md-active-tab="chosen_entity_type"
          @md-changed="id => chosen_entity_type = id"
        >
          <md-tab
            v-for="entity in social_platform_entities_mapper[chosen_provider]"
            :key="entity"
            :id="entity"
            :md-label="`${$translate(`social_media_accounts.providers.${chosen_provider}`)} ${$translate(`social_media_accounts.entity_types.${entity}`)}`"
          >
            <p class="md-title">
              {{ $translate("social_media_accounts.entity_list", { entity: $translate(`social_media_accounts.entity_types.${entity}`) }) }}
            </p>

            <div v-if="correct_loaded_entities.length" class="social-media__platforms">
              <!-- Show only for FB groups -->
              <p
                v-if="(
                  chosen_provider === social_media_provider_names.fb &&
                  entity === social_platform_entities_mapper[social_media_provider_names.fb][1]
                )"
                v-html="$translate(`social_media_accounts.fb_group_connection_message`)"
              />
              <div>
                <div
                  v-for="{ id, name, accountName, appName } in correct_loaded_entities"
                  :key="id"
                  class="social-media__page md-elevation-3"
                  :class="{
                    'social-media__page--selected': selected_entities.find(({ id: selected_id } = {}) => selected_id === id),
                    'social-media__page--no-img': true,
                  }"
                  @click="select_entity(id)"
                >
                  <p>{{ name }}</p>
                  <md-tooltip v-if="accountName" md-direction="top">{{ accountName }} | {{ appName }}</md-tooltip>
                </div>
              </div>
              <md-button class="md-raised md-primary" @click="selected_entities.length && upload_connected_entities(true)">
                {{ $translate("social_media_accounts.connect") }}
              </md-button>
            </div>
            <div v-else>
              {{
                $translate(
                  "social_media_accounts.no_entities_found",
                  {
                    source: $translate(`social_media_accounts.providers.${chosen_provider}`),
                    entity: $translate(`social_media_accounts.entity_types.${entity}`)
                  }
                )
              }}
              <br/>
              <md-button class="md-raised social-media__left-aligned-button" @click="fetch_correct_entities(entity)">
                {{ $translate("social_media_accounts.load_entities", { entity: $translate(`social_media_accounts.entity_types.${entity}`) }) }}
              </md-button>
            </div>

            <p class="md-title">
              {{ $translate("social_media_accounts.connected_entities", { entity: $translate(`social_media_accounts.entity_types.${entity}`) }) }}
            </p>
            <div v-if="correct_connected_entities.length" class="social-media__platforms">
              <div>
                <div
                  v-for="{ id, name, accountName, appName } in correct_connected_entities"
                  :key="id"
                  class="social-media__page md-elevation-3"
                  :class="{
                    'social-media__page--selected': selected_connected_entities.find(({ id: selected_id } = {}) => selected_id === id),
                    'social-media__page--no-img': true,
                  }"
                  @click="select_entity(id, true)"
                >
                  <p>{{ name }}</p>
                  <md-tooltip v-if="accountName" md-direction="top">{{ accountName }} | {{ appName }}</md-tooltip>
                </div>
              </div>
              <md-button class="md-raised md-accent" @click="selected_connected_entities.length && upload_connected_entities()">
                {{ $translate("social_media_accounts.disconnect") }}
              </md-button>
            </div>
            <p v-else>{{
              $translate(
                "social_media_accounts.no_entities_connected",
                {
                  source: $translate(`social_media_accounts.providers.${chosen_provider}`),
                  entity: $translate(`social_media_accounts.entity_types.${entity}`)
                }
              )
            }}</p>
          </md-tab>
        </md-tabs>
      </section>
      <div v-else class="social-media__admin-message">
        <h3 class="md-headline">
          {{ $translate("global_admin_specific_module") }}
          <a class="md-headline" :href="`https://${admin_domain}/admin/${project_config.project_id}/social/accounts`">Admin</a>
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { CONFIGURATION_STORE, SOCIAL_STORE } from "../../../constants/others_constants"
import {
  CONNECT_SOCIAL_MEDIA_ENTITIES, FETCH_FACEBOOK_AD_ACCOUNTS, FETCH_FACEBOOK_GROUPS, FETCH_FACEBOOK_PAGES,
  FETCH_INSTAGRAM_ACCOUNTS, GOOGLE_AUTH, SWITCH_FACEBOOK_ACCOUNT, SWITCH_GOOGLE_ACCOUNT, FETCH_GA_VIEWS
} from "../../../stores/Admin/social/constants"
import { social_platform_entities_mapper, social_media_providers, social_media_provider_names } from "../../../constants/social_media_constants"
import { SHARED_STORE } from "../../../../Shared/constants/other"
import { admin_domain } from "../../../../../data/other_constants"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview
  },
  data() {
    return {
      selected_entities: [],
      selected_connected_entities: [],
      chosen_provider: null,
      chosen_entity_type: null,
      social_platform_entities_mapper,
      social_media_providers,
      social_media_provider_names,
      new_analytics_view: "",
      admin_domain
    }
  },
  computed: {
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    ...mapState(SOCIAL_STORE, [
      "fb_pages",
      "connected_fb_pages",
      "fb_groups",
      "connected_fb_groups",
      "fb_ad_accounts",
      "connected_fb_ad_accounts",
      "ig_accounts",
      "connected_ig_accounts",
      "connected_google_analytics_views",
      "fb_user_auth_data",
      "google_user_auth_data",
      "google_analytics_views"
    ]),
    ...mapState(CONFIGURATION_STORE, [
      "project_config",
    ]),
    correct_connected_entities() {
      return this[`connected_${this.chosen_provider}_${this.chosen_entity_type}`] || []
    },
    correct_loaded_entities() {
      return (this[`${this.chosen_provider}_${this.chosen_entity_type}`] || []).filter(
        ({ id }) => !this.correct_connected_entities.find(({ id: connected_entity_id }) => connected_entity_id === id)
      )
    },
    correct_auth_user_data() {
      return this[`${
        this.chosen_provider === social_media_provider_names.ig ? social_media_provider_names.fb : this.chosen_provider
      }_user_auth_data`] || {}
    }
  },
  watch: {
    correct_loaded_entities(val) {
      if (val.length) this.selected_entities = this.correct_connected_entities.filter(
        ({ id }) => val.find(({ id: selected_entity_id }) => selected_entity_id === id)
      )
    },
    async chosen_provider(platform) {
      if (platform && platform !== social_media_provider_names.google) this.fetch_platform_entities(platform)
    }
  },
  methods: {
    ...mapActions(SOCIAL_STORE, {
      fetch_fb_pages: FETCH_FACEBOOK_PAGES,
      fetch_fb_groups: FETCH_FACEBOOK_GROUPS,
      fetch_fb_ad_accounts: FETCH_FACEBOOK_AD_ACCOUNTS,
      fetch_ig_accounts: FETCH_INSTAGRAM_ACCOUNTS,
      connect_entities: CONNECT_SOCIAL_MEDIA_ENTITIES,
      switch_fb_account: SWITCH_FACEBOOK_ACCOUNT,
      switch_google_account: SWITCH_GOOGLE_ACCOUNT,
      auth_google_user: GOOGLE_AUTH,
      fetch_google_analytics_views: FETCH_GA_VIEWS
    }),
    async fetch_platform_entities(platform) {
      social_platform_entities_mapper[platform].reduce(async (previous_promise, entity) => {
        await previous_promise;
        return await this.fetch_correct_entities(entity)
      }, Promise.resolve());
      this.chosen_entity_type = social_platform_entities_mapper[platform][0]
    },
    async upload_connected_entities(connecting) {
      let final_entities
      
      if (connecting) final_entities = [...this.selected_entities, ...this.correct_connected_entities]
      else final_entities = this.correct_connected_entities.filter(
        ({ id }) => !this.selected_connected_entities.find(({ id: connected_entity_id }) => connected_entity_id === id)
      )

      await this.connect_entities([final_entities, `${this.chosen_provider}_${this.chosen_entity_type}`])

      this.selected_connected_entities = []
      this.selected_entities = []
    },
    select_entity(selected_id, selecting_connected) {
      const correct_selected_entities = selecting_connected ? "selected_connected_entities" : "selected_entities"

      if (
        this[correct_selected_entities].find(({ id }) => id === selected_id)
      ) this[correct_selected_entities] = this[correct_selected_entities].filter(({ id }) => id !== selected_id)
      else this[correct_selected_entities].push(
        (selecting_connected ? this.correct_connected_entities : this.correct_loaded_entities).find(({ id }) => selected_id === id)
      )
    },
    async fetch_correct_entities(entity_type) {
      await this[`fetch_${this.chosen_provider}_${entity_type}`]()
    },
    async switch_account() {
      await this[`switch_${this.chosen_provider}_account`]()
      this.selected_entities = []
      this.selected_connected_entities = []
      this.chosen_provider = null
      this.chosen_entity_type = null
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;

  .social-media-accounts {
    &__account-switch {
      display: flex;
      align-items: center;

      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        margin-left: $default-size;

        @media (max-width: $tablet) {
          margin-left: $half-default-size;
        }
      }
    }

    &__google-signin {
      i {
        width: 18px;
        height: 18px;
        margin-right: 24px;
      }
    }
  }
</style>
